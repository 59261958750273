.countdown{
    display: flex;
}

.counter-display{
    display: flex;
    flex-flow: column;
    padding-left: 1rem;
}

.counter-display h2{
    font-size: 1rem;
    margin-bottom: 0;
}

.counter-display .readout{
    justify-content: flex-start;
    padding-left: 0;
}