.bar-fill{
    border: 1px solid #a7a7a7;
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 0.15rem;
    background: #f1f1f1;
    flex-grow: 1;
}

.bar-fill .bar-total{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}